import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Hero from '../components/shared/Hero'
import ImpactBlock from '../components/shared/ImpactBlock'
import FeaturedNews from '../components/shared/FeaturedNews'
import SEO from '../components/shared/Seo'


function Thankyou() {

  const { sanityThankYouPage } = useStaticQuery(graphql`
    {
    sanityThankYouPage {
      thankYouHero {
        heroText
        heroImage {
          asset {
            fluid {
              srcWebp
              src
              srcSet
              srcSetWebp
            }
            title
          }
        }
      }
      seoBlock {
        pageDescription
        pageKeyWords  
        pageTitle
        ogImage {
          asset {
            url
          }
        }
      }
    }
  }  
  `)

  const { thankYouHero } = sanityThankYouPage

  return (
    <>
      <SEO seoData={sanityThankYouPage?.seoBlock} slug='thankyou' />
      <Hero heroImage={thankYouHero.heroImage} heroText={thankYouHero.heroText} newsLetterVisible />
      <ImpactBlock />
      {/* <FeaturedNews /> */}
    </>
  )
}

export default Thankyou
